$( function() {
	const navBarToggler:JQuery<HTMLElement> = $('.navbar-toggler')
	navBarToggler.click(function() {
		const navBarContainer:JQuery<HTMLElement> = $('.navbar-container')
		if( /*is expanded*/navBarContainer.hasClass( 'navbar-gray' )){
			// TODO: what's up with this timeout?
			window.setTimeout( function() {
				navBarContainer.toggleClass( 'navbar-gray' )
				navBarContainer.toggleClass( 'shadow-lg' )
				hydrateFill()
			}, 250 )
		} else {
			navBarContainer.toggleClass( 'navbar-gray' )
			navBarContainer.toggleClass( 'shadow-lg' )
		}
		const navbarBg:JQuery<HTMLElement> = $('.navbar-active-bg')
		navbarBg.toggleClass( 'hide' )
		navbarBg.click( function() {
			navBarToggler.trigger( 'click' )
		})
		hydrateFill()
	})

	if( $('.navbar-container').length > 0 ){
		$(window).on("scroll load resize", function() {
			hydrateFill()
		})
	}

	hydrateFill() // NOTE: handles when going back to a page that is scrolled past the top
})


function hydrateFill() {
	const navBarContainer:JQuery<HTMLElement> = $('.navbar-container')
	const startY:number = navBarContainer.height() / 2
	const isScrolled:boolean = $(window).scrollTop() > startY
	const isExpanded:boolean = navBarContainer.hasClass( 'navbar-gray' )
	if( isScrolled || isExpanded ){
		navBarContainer.addClass( 'filled' )
	} else {
		navBarContainer.removeClass( 'filled' )
	}
}
